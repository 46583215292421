import { Basic } from 'summer';

export interface ConfiguracaoMIC extends Basic {
  /** campo utiliza o enum tipoAmbienteEnum */
  tipoAmbiente?: string;
  diaEmissaoDctfweb?: number;
  diaEmissaoDctfweb13?: number;
  diaEmissaoPgdas?: number;
  diaEmissaoPgmei?: number;
  diaBuscaCaixaPostal?: number;
  diaConsultaPagamentos?: number;
  diaEmissaoSitfis?: number;
  diaEmissaoCND?: number;
  emitirCNDVencimento?: string;
  mesEmissaoDEFIS?: number;
  diaEmissaoDEFIS?: number;
  arquivoCertificadoDigital?: string;
  nomeCertificadoDigital?: string;
  senhaCertificadoDigital?: string;
  consumerKey?: string;
  consumerSecret?: string;
  consumerKeyCND?: string;
  consumerSecretCND?: string;
  arquivos?: any[];
}

export const CLASS_NAME = 'br.com.projetusinformatica.calima.mic.model.dto.configuracaomic';
