import { MenuItem } from '../../../models/dtos/ger/menuItem';

const valeTransporte = 'Vale transporte';
const mfpMenu: MenuItem[] = [
  {
    icon: 'pe-7s-home',
    label: 'Home',
    to: '/mfp/dashboard',
  },
  {
    content: [
      {
        content: [
          {
            label: 'Dados pessoais',
            to: '/mfp/trabalhadorSearchView',
          },
          {
            label: 'Dependente',
            to: '/mfp/dependenteSearchView',
          },
          {
            label: 'Vínculo',
            to: '/mfp/vinculoSearchView',
          },
          {
            label: 'Férias',
            to: '/mfp/feriasEditView',
          },
          {
            label: 'Rescisão',
            to: '/mfp/rescisaoEditView',
          },
          {
            label: 'Resilição',
            to: '/mfp/resilicaoSearchView',
          },
          {
            calimaPro: true,
            label: 'Salário simplificado',
            to: '/mfp/trabalhadorSalarioSimplificadoProcessView',
          },
          {
            content: [
              {
                label: 'Normal',
                to: '/mfp/vinculoEventoEditView',
              },
              {
                label: 'Simplificado',
                to: '/mfp/vinculoEventoSimplificadoProcessView',
              },
              {
                calimaPro: true,
                label: 'Plano de saúde simplificado',
                to: '/mfp/vinculoEventoPlanoSaudeSimplificadoProcessView',
              },
            ],
            label: 'Evento',
          },
          {
            label: 'Tomador de serviço',
            to: '/mfp/vinculoTomadorServicoEditView',
          },
          {
            label: 'Afastamento',
            to: '/mfp/afastamentoEditView',
          },
          {
            label: 'Bolsa Qualificação',
            to: '/mfp/bolsaQualificacaoEditView',
          },
          {
            label: 'Ficha financeira',
            to: '/mfp/fichaFinanceiraEditView',
          },
          {
            label: 'PPP',
            to: '/mfp/pppSearchView',
          },
          {
            label: 'Atestado médico',
            to: '/mfp/AtestadoSearchView',
          },
          {
            label: 'CAT / S-2210 - Comunicação de Acidente de Trabalho',
            to: '/mfp/catSearchView',
          },
          {
            label: 'Exames / S-2220 - Monitoramento da Saúde do Trabalhador',
            to: '/mfp/monitoramentoSaudeTrabalhadorSearchView',
          },
          {
            label: 'Exames / S-2221 - Exame Toxicológico do Motorista Profissional Empregado',
            to: '/mfp/exameToxicologicoSearchView',
          },
          {
            label: 'S-2240 - Condições Ambientais do Trabalho',
            to: '/mfp/condicaoAmbientalTrabalhoSearchView',
          },
          {
            label: 'EPI - Equipamento de proteção individual',
            to: '/mfp/epiSearchView',
          },
          {
            label: 'Quadro de horário',
            to: '/mfp/quadroHorarioSearchView',
          },
        ],
        label: 'Trabalhador',
      },
      {
        label: 'Grupo de eventos',
        to: '/mfp/grupoEventoSearchView',
      },
      {
        content: [
          {
            label: 'Manutenção',
            to: '/mfp/tomadorServicoSearchView',
          },
          {
            label: 'Pagamento',
            to: '/mfp/pagamentoTomadorServicoSearchView',
          },
        ],
        label: 'Tomador de serviço / Obra de construção',
      },
      {
        content: [
          {
            label: 'Evento',
            to: '/mfp/eventoSearchView',
          },
          {
            content: [
              {
                label: 'Banco',
                to: '/mfp/bancoSearchView',
              },
              {
                label: 'Agência',
                to: '/mfp/agenciaSearchView',
              },
            ],
            label: 'Banco/Agência',
          },
          {
            label: 'Cargo',
            to: '/mfp/cargoSearchView',
          },
          {
            label: 'CBO',
            to: '/mfp/cboSearchView',
          },
          {
            label: 'Centro de custo',
            to: '/mfp/centroCustoSearchView',
          },
          {
            label: 'Código GPS',
            to: '/mfp/codigoGpsSearchView',
          },
          {
            label: 'Dias de aviso prévio',
            to: '/mfp/avisoPrevioDiasSearchView',
          },
          {
            label: 'Feriado',
            to: '/mfp/feriadoSearchView',
          },
          {
            label: 'Férias (dedução de faltas)',
            to: '/mfp/feriasDeducaoFaltasSearchView',
          },
          {
            label: 'Férias proporcionais (dias a considerar em relação as faltas)',
            to: '/mfp/feriasDeducaoFaltasProporcionaisSearchView',
          },
          {
            label: 'FPAS',
            to: '/mfp/fpasSearchView',
          },
          {
            label: 'INFPC - Informações da Previdência Complementar',
            to: '/mfp/previdenciaComplementarSearchView',
          },
          {
            label: 'Plano de saúde',
            to: '/mfp/planoSaudeSearchView',
          },
          {
            label: 'Rubrica',
            to: '/mfp/rubricaHomolognetSearchView',
          },
          {
            label: 'Sindicato',
            to: '/mfp/sindicatoSearchView',
          },
          {
            content: [
              {
                label: 'Fornecedor',
                to: '/mfp/forcliSearchView',
              },
              {
                label: 'Tarifa',
                to: '/mfp/valeTransporteSearchView',
              },
            ],
            label: valeTransporte,
          },
          {
            label: 'Valor e imposto',
            to: '/mfp/valorImpostoSearchView',
          },
          {
            label: 'Valor e imposto - PLR',
            to: '/mfp/valorImpostoPLRSearchView',
          },
        ],
        label: 'Tabelas',
      },
      {
        label: 'Customização de termos e documentos',
        to: '/mfp/customizacaoTermosSearchView',
      },
      {
        content: [
          {
            content: [
              {
                label: 'Guia',
                to: '/mfp/guiaGpsSearchView',
              },
              {
                label: 'Pagamento à cooperativa',
                to: '/mfp/pagamentoCooperativaSearchView',
              },
              {
                label: 'Produção rural',
                to: '/mfp/producaoRuralSearchView',
              },
            ],
            label: 'Geral',
          },
          {
            label: 'Compensação/Dedução',
            to: '/mfp/compensacaoSearchView',
          },
          {
            label: 'Trabalhador em diversos anexos',
            to: '/mfp/gpsDiversosAnexosSearchView',
          },
        ],
        label: 'GPS',
      },
      {
        content: [
          {
            label: 'Configuração',
            to: '/mfp/configDarfSearchView',
          },
          {
            label: 'Lançamento de débito/crédito',
            to: '/mfp/debitoCreditoDarfSearchView',
          },
        ],
        label: 'DARF',
      },
      {
        content: [
          {
            label: 'Empresa',
            to: '/mfp/configEmpresaEditView',
          },
          {
            content: [
              {
                label: 'DARF',
                to: '/mfp/contabilizacaoDARFSearchView',
              },
              {
                label: 'Evento',
                to: '/mfp/contabilizacaoEventoMFPSearchView',
              },
              {
                label: 'GPS',
                to: '/mfp/contabilizacaoGPSSearchView',
              },
              {
                label: 'Provisão férias/Décimo terceiro',
                to: '/mfp/contabilizacaoProvisaoEditView',
              },
              {
                label: 'GRRF',
                to: '/mfp/contabilizacaoGRRFEditView',
              },
              {
                label: 'Importar integração',
                to: '/mfp/importarIntegracaoFolhaContabilProcessView',
              },
            ],
            label: 'Integração contábil',
          },
        ],
        label: 'Configurações',
      },
    ],
    icon: 'pe-7s-edit',
    label: 'Manutenção',
  },
  {
    content: [
      {
        label: 'Calcular folha de pagamento',
        to: '/mfp/calculoParametrosProcessView',
      },
      {
        label: 'Consultar cálculos de funcionários',
        to: '/mfp/consultaCalculosProcessView',
      },
      {
        label: 'Histórico de cálculos',
        to: '/mfp/calculoReferenciaSearchView',
      },
      {
        label: 'Operações em lote',
        to: '/mfp/operacaoEmLoteProcessView',
      },
      {
        label: 'Integração grupo evento',
        to: '/mfp/integracaoGrupoEventoProcessView',
      },
      {
        label: 'Fechamento/Restauração',
        to: '/mfp/fechamentoRestauracaoMFPProcessView',
      },
      {
        content: [
          {
            calimaPro: true,
            label: 'Calcular provisão de férias analítico',
            to: '/mfp/calculoProvisaoProcessView',
          },
          {
            calimaPro: true,
            label: 'Histórico de cálculos de provisão de férias analítico',
            to: '/mfp/historicoProvisaoSearchView',
          },
        ],
        label: 'Provisão',
      },
    ],
    icon: 'pe-7s-play',
    label: 'Processos',
  },
  {
    content: [
      {
        content: [
          {
            label: 'B.E.M Covid-19',
            to: '/mfp/bemExportacaoProcessView',
          },
          {
            label: 'CAGED',
            to: '/mfp/exportacaoCagedProcessView',
          },
          {
            label: 'DIRF',
            to: '/mfp/exportacaoDirfProcessView',
          },
          {
            label: 'HomologNet',
            to: '/mfp/homologNetProcessView',
          },
          {
            label: 'MANAD',
            to: '/mfp/exportacaoManadProcessView',
          },
          {
            label: 'NIS',
            to: '/mfp/nisProcessView',
          },
          {
            label: 'RAIS',
            to: '/mfp/exportacaoRaisProcessView',
          },
          {
            label: 'SEFIP',
            to: '/mfp/sefipProcessView',
          },
          {
            label: 'GRRF',
            to: '/mfp/exportacaoGrrfProcessView',
          },
          {
            label: 'Contracheque eletrônico',
            to: '/mfp/exportacaoContraChequeSearchView',
          },
          {
            label: 'Seguro-Desemprego',
            to: '/mfp/seguroDesempregoProcessView',
          },
          {
            label: 'Bolsa Qualificação',
            to: '/mfp/bolsaQualificacaoExportacaoProcessView',
          },
        ],
        label: 'Exportação',
      },
      {
        content: [
          {
            label: 'NIS',
            to: '/mfp/importacaoNisProcessView',
          },
          {
            label: 'Ponto eletrônico',
            to: '/mfp/importacaoPontoEletronicoProcessView',
          },
          {
            label: 'RAIS/MANAD/SEFIP',
            to: '/mfp/importacaoDOSProcessView',
          },
          {
            label: 'Trabalhador / Vínculo - planilha do excel (formato CSV, XLS e XLSX)',
            to: '/mfp/importacaoVinculoProcessView',
          },
          {
            label: 'Importar XML dos eventos do eSocial',
            to: '/mfp/importacaoXMLEventosArquivosDigitaisProcessView',
          },
        ],
        label: 'Importação',
      },
    ],
    icon: 'pe-7s-folder',
    label: 'Arquivos digitais',
  },
  {
    content: [
      {
        content: [
          {
            label: 'Monitor de eventos do eSocial',
            to: '/mfp/monitorEventoEsocialProcessView',
          },
          {
            label: 'Qualificação cadastral',
            to: '/mfp/trabalhadorQualificacaoCadastralExpoProcessView',
          },
        ],
        label: 'Exportação',
      },
      {
        content: [
          {
            label: 'Sincronização de eventos',
            to: '/mfp/sincronizacaoEventosEsocialProcessView',
          },
          {
            label: 'Importar XML dos eventos do eSocial',
            to: '/mfp/importacaoXMLEventosArquivosDigitaisProcessView',
          },
        ],
        label: 'Importação',
      },
      {
        content: [
          {
            label: 'Geral',
            to: '/mfp/empresaEsocialConfigView',
          },
          {
            content: [
              {
                label: 'Instituição de ensino/Entidade educativa',
                to: '/mfp/instituicaoEnsinoSearchView',
              },
              {
                label: 'Responsável',
                to: '/mfp/responsavelMenorAprendizSearchView',
              },
            ],
            label: 'Aprendiz/Estagiário',
          },
          {
            label: 'Contribuição Sindical Patronal',
            to: '/mfp/contribuicaoSindicalPatronalSearchView',
          },
          {
            label: 'Lotação Tributária',
            to: '/mfp/lotacaoTributariaSearchView',
          },
          {
            label: 'Natureza da rubrica',
            to: '/mfp/naturezaRubricaSearchView',
          },
          {
            content: [
              {
                label: 'Processos',
                to: '/mfp/processoJudicialSearchView',
              },
              {
                label: 'Informação Complementar de Processo Judicial',
                to: '/mfp/infoComplementarProcessoJudicialSearchView',
              },
            ],
            label: 'Processos Administrativos/Judiciais',
          },
        ],
        label: 'Configurações',
      },
      {
        content: [
          {
            label: 'CAT / S-2210 - Comunicação de Acidente de Trabalho',
            to: '/mfp/catSearchView',
          },
          {
            label: 'Exames / S-2220 - Monitoramento da Saúde do Trabalhador',
            to: '/mfp/monitoramentoSaudeTrabalhadorSearchView',
          },
          {
            label: 'Exames / S-2221 - Exame Toxicológico do Motorista Profissional Empregado',
            to: '/mfp/exameToxicologicoSearchView',
          },
          {
            label: 'S-2240 - Condições Ambientais do Trabalho',
            to: '/mfp/condicaoAmbientalTrabalhoSearchView',
          },
          {
            label: 'Importar XML dos eventos S-2210 / S-2220 / S-2240',
            to: '/mfp/importacaoXMLEventosProcessView',
          },
          {
            content: [
              {
                content: [
                  {
                    label: 'Agente causador',
                    to: '/mfp/agenteCausadorSearchView',
                  },
                  {
                    label: 'Natureza da lesão',
                    to: '/mfp/naturezaLesaoSearchView',
                  },
                  {
                    label: 'Parte do corpo atingida',
                    to: '/mfp/parteCorpoAtingidaSearchView',
                  },
                  {
                    label: 'Situação geradora de doença profissional',
                    to: '/mfp/situacaoGeradoraSearchView',
                  },
                ],
                label: 'Acidente de trabalho',
              },
              {
                content: [
                  {
                    label: 'Geral',
                    to: '/mfp/ambienteTrabalhoSearchView',
                  },
                  {
                    label: 'Fator de risco',
                    to: '/mfp/fatorRiscoAmbienteTrabalhoSearchView',
                  },
                ],
                label: 'Ambiente de trabalho',
              },
              {
                content: [
                  {
                    label: 'Procedimento realizado',
                    to: '/mfp/procedimentoRealizadoSearchView',
                  },
                ],
                label: 'Saúde do trabalhador',
              },
            ],
            label: 'Tabelas',
          },
        ],
        label: 'SST',
      },
      {
        content: [
          {
            label: 'S-2500 - Processo',
            to: '/mfp/processoTrabalhistaSearchView',
          },
          {
            label: 'S-2501 - Tributos',
            to: '/mfp/tributosProcessoTrabalhistaSearchView',
          },
        ],
        label: 'Processo trabalhista',
      },
    ],
    icon: 'pe-7s-id',
    label: 'eSocial',
  },
  {
    content: [
      {
        label: 'Abono pecuniário',
        to: '/mfp/abonoPecuniarioReportView',
      },
      {
        label: 'Acordo de compensação de horas',
        to: '/mfp/acordoCompensacaoHorasReportView',
      },
      {
        label: 'Acordo de prorrogação de hora extra',
        to: '/mfp/acordoProrrogacaoHoraExtraReportView',
      },
      {
        label: 'Analítico da GRCSU',
        to: '/mfp/analiticoGRCSUReportView',
      },
      {
        label: 'Analítico do cálculo de rescisão - perda 13º e férias',
        to: '/mfp/analiticoRescisaoReportView',
      },
      {
        label: 'Atestado de saúde ocupacional',
        to: '/mfp/atestadoSaudeOcupacionalReportView',
      },
      {
        label: 'Aviso prévio',
        to: '/mfp/avisoPrevioReportView',
      },
      {
        label: 'Aviso de desligamento por justa causa',
        to: '/mfp/avisoDesligamentoJustaCausaReportView',
      },
      {
        label: 'Análise RAIS',
        to: '/mfp/analiseRaisReportView',
      },
      {
        label: 'Carta de advertência',
        to: '/mfp/cartaAdvertenciaReportView',
      },
      {
        label: 'Carta de suspensão',
        to: '/mfp/cartaSuspensaoReportView',
      },
      {
        label: 'Carta de preposição',
        to: '/mfp/cartaPreposicaoReportView',
      },
      {
        label: 'Carta de recomendação',
        to: '/mfp/cartaRecomendacaoReportView',
      },
      {
        calimaPro: true,
        label: 'Carta de oposição',
        to: '/mfp/cartaOposicaoReportView',
      },
      {
        label: 'Comprovante de rendimentos',
        to: '/mfp/comprovanteRendimentoReportView',
      },
      {
        label: 'Conferência da GRRF',
        to: '/mfp/conferenciaGRRFReportView',
      },
      {
        label: 'Décimo terceiro - provisão',
        to: '/mfp/provisaoDecimoTerceiroReportView',
      },
      {
        label: 'Declaração de encargos de família para fins de I.R.',
        to: '/mfp/declaracaoFamiliaImpostoRendaReportView',
      },
      {
        label: 'Declaração de mais de um vínculo empregatício',
        to: '/mfp/declaracaoOutroVinculoEmpregaticioReportView',
      },
      {
        label: 'Declaração de valores pagos à GPS',
        to: '/mfp/valoresPagosGpsReportView',
      },
      {
        label: 'Dispensa por término de contrato de experiência',
        to: '/mfp/dispensaTerminoContratoReportView',
      },
      {
        label: 'Ficha atualização dados cadastrais',
        to: '/mfp/atualizacaoDadosCadastraisReportView',
      },
      {
        label: 'Ficha de anotações CTPS',
        to: '/mfp/fichaAnotacoesCTPSReportView',
      },
      {
        label: 'Ficha financeira',
        to: '/mfp/fichaFinanceiraReportView',
      },
      {
        label: 'Folha analítica',
        to: '/mfp/folhaAnaliticaReportView',
      },
      {
        label: 'Folha de presença',
        to: '/mfp/folhaPresencaReportView',
      },
      {
        label: 'Folha resumida',
        to: '/mfp/folhaResumidaReportView',
      },
      {
        label: 'Horário de trabalho',
        to: '/mfp/horarioTrabalhoReportView',
      },
      {
        label: 'Planilha de depósito FGTS',
        to: '/mfp/planilhaDepositoFgtsReportView',
      },
      {
        label: 'Relação de eventos',
        to: '/mfp/relacaoEventoReportView',
      },
      {
        label: 'Relação de contas bancárias para pagamento',
        to: '/mfp/contaBancariaPagamentoReportView',
      },
      {
        label: 'Relação de tomadores de serviço',
        to: '/mfp/relacaoTomadoresServicoReportView',
      },
      {
        label: 'Resumo da folha',
        to: '/mfp/resumoFolhaReportView',
      },
      {
        label: 'Termo aditivo',
        to: '/mfp/termoAditivoReportView',
      },
      {
        label: 'Termo aditivo de transferência',
        to: '/mfp/termoAditivoTransferenciaReportView',
      },
      {
        label: 'Termos suspensão/redução Covid-19',
        to: '/mfp/termoMP936ReportView',
      },
      {
        label: 'Termo de declaração de atividade ou serviço essencial',
        to: '/mfp/termoAtividadeServicoEssencialReportView',
      },
      {
        label: 'Termo de rescisão do contrato de trabalho',
        to: '/mfp/termoRescisaoReportView',
      },
      {
        label: 'Simples conferência SEFIP',
        to: '/mfp/sefipReportView',
      },
      {
        label: 'Sindicatos cadastrados',
        to: '/mfp/sindicatoReportView',
      },
      {
        label: 'Seguro-Desemprego',
        to: '/mfp/seguroDesempregoReportView',
      },
      {
        content: [
          {
            label: 'MANAD',
            to: '/mfp/ajusteImportacaoManadReportView',
          },
          {
            label: 'RAIS',
            to: '/mfp/ajusteImportacaoRaisReportView',
          },
          {
            label: 'SEFIP',
            to: '/mfp/ajusteImportacaoSefipReportView',
          },
        ],
        label: 'Ajustes na importação',
      },
      {
        content: [
          {
            label: 'Relatório mensal de término de contrato de experiência',
            to: '/mfp/contratoExperienciaPendeteReportView',
          },
          {
            label: 'Contratos de trabalho',
            to: '/mfp/contratosTrabalhoReportView',
          },
        ],
        label: 'Contrato de trabalho',
      },
      {
        content: [
          {
            label: 'Qualificação Cadastral',
            to: '/mfp/qualificacaoCadastralReportView',
          },
          {
            label: 'Recibo de protocolo',
            to: '/mfp/reciboProtocoloEsocialReportView',
          },
          {
            label: 'Comparativo Calima x eSocial',
            to: '/mfp/comparativoEsocialReportView',
          },
          {
            label: 'Ocorrências eSocial (falhas/advertências)',
            to: '/mfp/ocorrenciaEsocialReportView',
          },
        ],
        label: 'eSocial',
      },
      {
        content: [
          {
            label: 'Carteira de Trabalho',
            to: '/mfp/etiquetaCarteiraTrabalhoReportView',
          },
          {
            label: 'Cartão de ponto',
            to: '/mfp/etiquetaCartaoPontoReportView',
          },
          {
            label: 'CTPS aviso de projeção',
            to: '/mfp/ctpsAvisoProjecaoAvisoPrevioIndenizadoReportView',
          },
        ],
        label: 'Etiquetas',
      },
      {
        label: 'Eventos utilizados na referência',
        to: '/mfp/eventoReferenciaReportView',
      },
      {
        content: [
          {
            label: 'Aviso',
            to: '/mfp/avisoFeriasReportView',
          },
          {
            label: 'Controle',
            to: '/mfp/controleFeriasReportView',
          },
          {
            label: 'Gozadas',
            to: '/mfp/feriasGozadasReportView',
          },
          {
            label: 'Recibo',
            to: '/mfp/reciboFeriasReportView',
          },
          {
            label: 'Provisão',
            to: '/mfp/provisaoFeriasReportView',
          },
          {
            calimaPro: true,
            label: 'Provisão analítico',
            to: '/mfp/provisaoFeriasAnaliticoReportView',
          },
          {
            label: 'Pendentes',
            to: '/mfp/relacaoFeriasPendentesReportView',
          },
        ],
        label: 'Férias',
      },
      {
        label: 'Declaração de opção FGTS',
        to: '/mfp/declaracaoOpcaoFgtsReportView',
      },
      {
        content: [
          {
            label: 'DARF - Guia Avulsa',
            to: '/mfp/guiaAvulsaSearchView',
          },
          {
            label: 'DARF',
            to: '/mfp/guiaDarfReportView',
          },
          {
            label: 'GRFC',
            to: '/mfp/guiaGrfcReportView',
          },
          {
            label: 'GPS',
            to: '/mfp/guiaGpsReportView',
          },
          {
            label: 'GRCSU',
            to: '/mfp/guiaGrcsuReportView',
          },
        ],
        label: 'Guias',
      },
      {
        content: [
          {
            label: 'Relação de salário de contribuição ao INSS',
            to: '/mfp/relacaoSalarioContribuicaoReportView',
          },
          {
            label: 'Requerimento de benefício',
            to: '/mfp/beneficioIncapacidadeReportView',
          },
        ],
        label: 'INSS',
      },
      {
        content: [
          {
            label: 'Filiação sindical',
            to: '/mfp/filiacaoSindicalReportView',
          },
          {
            label: 'Carteira de Trabalho Digital',
            to: '/mfp/listagemCarteiraReportView',
          },
          {
            label: 'Relação de dependentes IRPF',
            to: '/mfp/relacaoDependenteIRPFReportView',
          },
        ],
        label: 'Listagem',
      },
      {
        content: [
          {
            label: 'DARF',
            to: '/mfp/memoriaCalculoDARFReportView',
          },
          {
            label: 'Diversos',
            to: '/mfp/memoriaCalculoVinculoReportView',
          },
          {
            label: 'GPS',
            to: '/mfp/memoriaCalculoGpsReportView',
          },
          {
            label: 'Maior remuneração',
            to: '/mfp/memoriaCalculoMaiorRemuneracaoReportView',
          },
        ],
        label: 'Memória de cálculo',
      },
      {
        content: [
          {
            label: 'Contracheque',
            to: '/mfp/contraChequeReportView',
          },
          {
            label: 'Vale refeição e alimentação',
            to: '/mfp/valeRefeicaoReciboReportView',
          },
          {
            label: 'Entrega/Devolução da Carteira de Trabalho',
            to: '/mfp/reciboEntregaDevolucaoCtpsReportView',
          },
          {
            label: 'Recibo de cesta básica',
            to: '/mfp/reciboCestaBasicaReportView',
          },
          {
            label: 'Pró-labore',
            to: '/mfp/reciboProLaboreReportView',
          },
          {
            label: 'Participação nos lucros',
            to: '/mfp/reciboParticipacaoLucroReportView',
          },
          {
            label: 'R.P.A.',
            to: '/mfp/rpaReportView',
          },
          {
            label: valeTransporte,
            to: '/mfp/valeTransporteReciboReportView',
          },
        ],
        label: 'Recibos',
      },
      {
        content: [
          {
            label: 'Ficha',
            to: '/mfp/fichaSalarioFamiliaReportView',
          },
          {
            label: 'Relação',
            to: '/mfp/salarioFamiliaReportView',
          },
          {
            label: 'Termo de responsabilidade',
            to: '/mfp/termoResponsabilidadeReportView',
          },
        ],
        label: 'Salário família',
      },
      {
        content: [
          {
            label: 'Exame ocupacional - pendente',
            to: '/mfp/RelacaoExamesPendentesReportView',
          },
          {
            label: 'Admitidos e demitidos no período',
            to: '/mfp/admitidosDemitidosPeriodoReportView',
          },
          {
            label: 'Relatório de pensionistas',
            to: '/mfp/pensionistasReportView',
          },
          {
            label: 'Afastamentos',
            to: '/mfp/afastamentoTrabalhadorReportView',
          },
          {
            label: 'Aniversariantes',
            to: '/mfp/aniversarianteReportView',
          },
          {
            label: 'Com tomador de serviço',
            to: '/mfp/relacaoFuncionarioTomadorReportView',
          },
          {
            label: 'NIS',
            to: '/mfp/nisReportView',
          },
          {
            label: 'Relação completa',
            to: '/mfp/relacaoFuncionarioCompletaReportView',
          },
          {
            label: 'Relação de cargos e salários',
            to: '/mfp/relacaoCargosSalariosReportView',
          },
          {
            label: 'Relação normal',
            to: '/mfp/relacaoTrabalhadorNormalReportView',
          },
          {
            label: 'Relação simplificada',
            to: '/mfp/relacaoTrabalhadorSimplesReportView',
          },
          {
            label: 'Relação de evento x trabalhador',
            to: '/mfp/relacaoEventoTrabalhadorReportView',
          },
          {
            label: 'Registro de empregados',
            to: '/mfp/registroEmpregadoReportView',
          },
          {
            label: 'Relação de faltas por trabalhador',
            to: '/mfp/relacaoFaltasTrabalhadorReportView',
          },
          {
            label: 'Termo de quitação anual',
            to: '/mfp/quitacaoAnualReportView',
          },
        ],
        label: 'Trabalhadores',
      },
      {
        content: [
          {
            label: 'Controle individual',
            to: '/mfp/valeTransporteControleIndividualReportView',
          },
          {
            label: 'Registro de participação',
            to: '/mfp/registroParticipacaoReportView',
          },
          {
            label: 'Opção de desistência',
            to: '/mfp/valeTransporteDesistenciaReportView',
          },
        ],
        label: valeTransporte,
      },
      {
        label: 'Profissional Profissiográfico Previdenciário - PPP',
        to: '/mfp/pppReportView',
      },
      {
        label: 'Comunicação de Acidente de Trabalho - CAT',
        to: '/mfp/catReportView',
      },
      {
        label: 'Equipamento de Proteção Individual - EPI',
        to: '/mfp/epiReportView',
      },
      {
        label: 'Vencimento de exames ocupacionais SST',
        to: '/mfp/exameOcupacionalSSTReportView',
      },
    ],
    icon: 'pe-7s-print',
    isReportList: true,
    label: 'Relatórios',
  },
];

export default mfpMenu;
