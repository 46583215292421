import React, { FC, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Reducers } from '../../../../store/ducks';
import { toggleModalDefisIntegraContador, toggleModalPgDasIntegraContador } from '../../../../store/ducks/global.duck';
import FullSizeModal, { FullSizeModalRef } from '../../../components/fullSizeModal/FullSizeModal';

import adIntegraContadorPgDas from '../../../../assets/adIntegraContadorPgDas.png';
import './ModalPropagandaIntegraContador.css';

const ModalPropagandaIntegraContador: FC = () => {
  const {
    globalReducer: { showModalPgDasIntegraContador },
  } = useSelector<Reducers, Reducers>(state => state);

  const modal = useRef<FullSizeModalRef>();
  const dispatch = useDispatch();

  const onClose = () => {
    if (showModalPgDasIntegraContador) {
      dispatch(toggleModalPgDasIntegraContador());
      window.sessionStorage.setItem('MENSAGEM_PGDAS_INTEGRA', 'true');
    } else {
      dispatch(toggleModalDefisIntegraContador());
      window.sessionStorage.setItem('MENSAGEM_DEFIS_INTEGRA', 'true');
    }
  };

  return (
    showModalPgDasIntegraContador && (
      <FullSizeModal className="modal-propaganda-integra-contador" onClose={onClose} ref={modal} aspectRatio={1.5399}>
        <div className="slide">
          <img src={adIntegraContadorPgDas} alt="" />
        </div>
        <div className="ad-footer">
          <div className="btns">
            <a className={'btn-conhecer'} href="https://www.calimaerp.com/integracontador" target="_blank" rel="noopener noreferrer">
              <button>Conhecer o Integra Contador</button>
            </a>
            <button onClick={onClose} className={'btn-prosseguir'}>
              Prosseguir manualmente
            </button>
          </div>
        </div>
      </FullSizeModal>
    )
  );
};

export default ModalPropagandaIntegraContador;
